<template>
    <div class="comblank" :style="[{lineHeight:com.style['height'],textAlign:com.align},com.style]">{{com.text}}</div>
</template>
<script>
	export default {
		data () {
			return {
				
			}
		},
		methods: {
			getBinding(){
				return [{label: '文本',field:'text'}]
			}
		},
		  props: {
			  com:Object,
			  page:Object
		  }
	}
</script>
<style scoped="scoped">
	/* .comblank{ */
		/* display: flex; */
		/* justify-content: center; */
	/* } */
</style>