<template>
    <div class=''>
      <div class="spliter" @mousedown="mousedown($event, $refs.btmTable)"></div>
      <div class="drw">
          <!-- <el-button type="primary" size="mini" @click="saveMethod">保存</el-button> -->
          <el-button type="primary" size="mini" @click="addParams(value)">添加接参</el-button>
          <el-button type="warning" class="el-icon-top" size="mini" v-if="currBtn"
              @click="upGo(value, currBtn)">上移</el-button>
          <el-button type="warning" class="el-icon-bottom" size="mini" v-if="currBtn"
              @click="downGo(value, currBtn)">下移</el-button>
          <el-table :data="value" stripe tooltip-effect="dark" style="width: 100%" highlight-current-row
              @current-change="currBtn = $event">
              <el-table-column label="操作" width="120">
                  <template slot-scope="scope">
                      <el-button type="danger" size="mini" icon="el-icon-close" @click="deleteGo(value, scope.$index)" round></el-button>
                  </template>
              </el-table-column>
              <el-table-column label="传入字段" width="150">
                  <template slot-scope="scope">
                      <input v-model="scope.row.formField"
                          :style="scope.row == currBtn ? 'color:red;font-size:14px;width:100%' : 'width:100%'"
                          placeholder="字段名"></input>
                  </template>
              </el-table-column>
              <el-table-column label="" width="80">
                  <i class="el-icon-right"></i>
              </el-table-column>
              <el-table-column label="接收字段" width="150">
                  <template slot-scope="scope">
                      <input v-model="scope.row.toField"
                          :style="scope.row == currBtn ? 'color:red;font-size:14px;width:100%' : 'width:100%'"
                          placeholder="字段名"></input>
                  </template>
              </el-table-column>
          </el-table>
      </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
          btmSize: '400px',
          currBtn: null,
        };
    },
    created() {},
    methods: {
      mousedown(evt, sourceDom) {
            let height = parseInt(sourceDom.size)
            let pageY = evt.pageY
            let start = true
            this.$refs.fc.style.display = 'block'
            let that = this
            document.onmousemove = (event) => {
                if (start) {
                    // sourceDom.height ='80%' //= height + event.pageY - pageY + "px";
                    that.btmSize = sourceDom.size = height - (event.pageY - pageY) + "px";
                }
            };
            document.onmouseup = () => {
                this.$refs.fc.style.display = 'none'
                document.onmousemove = null;
                document.onmouseup = null;
                start = false
                return false;
            }
        },
        addParams(parm) {
            if(!parm) parm= []
            parm.push({"formField": "","toField": ""})
            this.value = parm
            this.$emit('input',this.value)
        },
        upGo(arr, row) {
            let index = this.getIndex(arr, row)
            if (index !== 0) {
                arr[index] = arr.splice(index - 1, 1, arr[index])[0]
            } else {
                arr.push(arr.shift())
            }
        },
        downGo(arr, row) {
            let index = this.getIndex(arr, row)
            if (index !== arr.length - 1) {
                arr[index] = arr.splice(index + 1, 1, arr[index])[0]
            } else {
                arr.unshift(arr.splice(index, 1)[0])
            }
        },
        getIndex(arr, row) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] == row) return i
            }
        },
        deleteGo(arr, index) {
            arr.splice(index, 1)
        },
    },
    components:{},
    props: {
        value: Array,
        comData: Object,
        saveMethod: Function
    }
};
</script>

<style scoped>

.drw {
    padding-left: 20px;
}

.spliter {
    width: 100%;
    height: 5px;
    background-color: #006666;
    cursor: s-resize;
}

.fc {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(216, 216, 216, 0.1);
    z-index: 10000;
}

.margin-tb {
    margin: 5px 0;
}
</style>
