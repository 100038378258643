<template>
  <div>
      <el-form label-width="100px" size="mini">
        <el-form-item label="组件说明">
          <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
        </el-form-item>
        <el-form-item label="圆角">
          <el-input-number v-model="com.borderRadius" :min="0" :max="100" :step="10" label="边距(最大24)" controls-position="right" size="mini"></el-input-number>
        </el-form-item>
        <el-form-item label="每行几个">
          <el-input-number v-model="com.colCount" :min="2" :max="5" label="宽度(最大3)" controls-position="right" size="mini"></el-input-number>
        </el-form-item>
        <el-form-item label="超出n行滚动">
          <el-input-number v-model="com.rowCount" :min="1" controls-position="right" size="mini"></el-input-number>
        </el-form-item>
        <el-form-item label="图片样式">
          <jfStyle v-model="com.imgStyle"></jfStyle>
        </el-form-item>
        <el-form-item label="标题样式">
          <jfStyle v-model="com.textStyle"></jfStyle>
        </el-form-item>
        <el-form-item label="副标题样式">
          <jfStyle v-model="com.subtextStyle"></jfStyle>
        </el-form-item>
        <el-form-item label="添加图片">
          <el-button type="primary" size="mini" @click="add()">添加图片</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="com.data" stripe tooltip-effect="dark" style="width: 100%" :cellStyle="{padding:'0px'}">
        <el-table-column width="80" label="图片">
          <template slot-scope="scope">
            <selectorImg v-model="scope.row.imgUrl"></selectorImg>
          </template>
        </el-table-column>
        <el-table-column width="90" label="标题">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name"></el-input>
          </template>
        </el-table-column>
        <el-table-column width="90" label="副标题">
          <template slot-scope="scope">
            <el-input v-model="scope.row.subname"></el-input>
          </template>
        </el-table-column>
        <el-table-column width="150" label="链接">
          <template slot-scope="scope">
            <selectorPage v-model="scope.row.pageName" :comData="comData"></selectorPage>
          </template>
        </el-table-column>
        <el-table-column width="90" label="参数">
          <template slot-scope="scope">
            <el-input v-model="scope.row.query"></el-input>
          </template>
        </el-table-column>
        <el-table-column width="90" label="样式">
          <template slot-scope="scope">
            <jfStyle v-model="scope.row.style"></jfStyle>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="70">
          <template slot-scope="scope">
            <div class="el-icon-top" @click="common.up(com.data,scope.$index)"></div>
            <div class="el-icon-bottom" @click="common.down(com.data,scope.$index)"></div>
            <div class="el-icon-close" @click="common.del(com.data,scope.$index)"></div>
          </template>
        </el-table-column>
      </el-table>
    	<jfExplain v-model="drawer" :name="com.cName || '中部导航'" :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style scoped="scoped">

</style>
<script>
export default {
  data () {
    return {
      current: null,
      drawer: false,
      desc: "中部导航是一个主要用于多个分类的跳转组件，在使用时可以配置它的导航图标、标题及跳转链接、参数等……",
      fieldData:[
        {field: 'imgUrl',explain: '导航图片地址', type: 'string'},
        {field: 'name',explain: '导航标题', type: 'string'},
        {field: 'subname',explain: '导航副标题', type: 'string'},
        {field: 'pageName',explain: '跳转链接', type: 'string'},
        {field: 'query',explain: '跳转参数', type: 'string'},
      ]
    }
  },
  methods: {
    add () {
      this.com.data.push({ name: '导航', imgUrl: '', pageName: '' })
    }
  },
  props: { com: {}, comData: Object }
}
</script>
