<template>
  <div class="navBox" :style="[com.style]">
    <div class="navBox">
      <ul>
        <li v-for="(item, index) in data" :key="index" class="clear">
					<div v-for="(col, i) in item" :key="i" class="fl" :class="'colLI'+com.colCount" :style="[col.style]">
						<span class="navImg" :style="[{borderRadius:com.borderRadius+'%',overflow: 'hidden'},com?.imgStyle]">
							<img :src="$route.params.url+col.imgUrl" :onerror="common.imgOnError" />
						</span>
						<div
							class="navText"
							:style="[{color:com.style.color},com.textStyle || {}]"
						>{{col.name || ''}}</div>
						<div
							class="subNavText"
							v-html="col.subname || ''"
							:style="[{color:com.style.color},com.subtextStyle || {}]"
						></div>
					</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: []
    }
  },
  created: function () {
		this.data = this.chunkArray(this.com.data,this.com.colCount*this.com.rowCount)
		// console.log(this.data);
  },
	methods: {
		chunkArray(array, chunkSize) {
			const chunks = [];
			for (let i = 0; i < array.length; i += chunkSize) {
				chunks.push(array.slice(i, i + chunkSize));
			}
			return chunks;
		},
		getBinding(){
		  return [{label: '总金额',field:'money'},{label: '总数量', field: 'count'}]
		}
	},
  props: {
    pageObj: Object,
    com: Object
  },
  watch: {
    'com.data': {
			deep: true,
      handler: function (newValue, oldValue) {
					this.data = this.chunkArray(newValue,this.com.colCount*this.com.rowCount)
      }
    },
    'com.colCount': {
			deep: true,
      handler: function (newValue, oldValue) {
					this.data = this.chunkArray(this.com.data,this.com.colCount*this.com.rowCount)
      }
    },
    'com.rowCount': {
			deep: true,
      handler: function (newValue, oldValue) {
					this.data = this.chunkArray(this.com.data,this.com.colCount*this.com.rowCount)
      }
    }
  }
}
</script>
<style scoped>
	.navBox {
	  /* width: 100%; */
    /* background-color: #fff; */
		box-sizing: border-box;
	}
	.navBox .navBox ul {
		display: flex;
    padding: 0;
    margin: 0;
		width: 100%;
		height: 100%;
		overflow-x: auto;
	}
	.navBox .navBox ul li {
		flex-shrink: 0;
		width: 100%;
		height: 100%;
    list-style-type: none;
    margin-left: 0;
		display: flex;
		flex-wrap: wrap;
		text-align: center;
		box-sizing: border-box;
	}
	.fl {
		padding: 10px;
		box-sizing: border-box;
	}
	.navBox .navBox ul li .navImg {
	  display: block;
	  height: 36px;
	  width: 36px;
	  /* background-color: #ddd; */
	  margin: 0 auto;
	}
	.navBox .navBox ul li .navImg img {
	  height: 100%;
	  width: 100%;
	}
	.navBox .navBox ul li .navText {
	  font-size: 12px;
	  color: #444;
	  font-weight: 400;
	  margin-top: 7px;
	  word-wrap: break-word;
	  word-break: break-all;
	  height: 18px;
	  line-height: 18px;
	  overflow: hidden;
	}
	.navBox .navBox ul li .subNavText {
	  font-size: 12px;
	  color: #999;
	  font-weight: 400;
	  word-wrap: break-word;
	  word-break: break-all;
	  overflow: hidden;
	}
	.navBox .colLI2 {
	  width: 50%;
	}
	.navBox .colLI3 {
	  width: 33.33%;
	}
	.navBox .colLI4 {
	  width: 25%;
	}
	.navBox .colLI5 {
	  width: 20%;
	}
	.navBox .actve {
	  border-radius: 50%;
	  overflow: hidden;
	}
</style>
