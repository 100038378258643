<template>
  <div>
    <el-form label-width="100px" size="mini">
      <el-form-item label="组件说明">
        <el-button @click="drawer = true" type="primary">查看</el-button> <span><i class="el-icon-info"></i></span>
      </el-form-item>
      <el-form-item class="margin-tb" label-width="0px">
          <div style="display: flex;margin: 10px 0;">
              <el-button style="width: 150px;" type="primary" plain size="mini"
                  @click="colVisible = true">按钮设置</el-button>
              <el-button style="width: 150px;" type="primary" plain size="mini" @click="paramsVisible = true">接参设置</el-button>
          </div>
      </el-form-item>
      <el-form-item label="对齐方式">
          <el-radio-group v-model="com.align" @input="handlerLocation">
              <el-radio-button :label="'leftTop'">左上角</el-radio-button>
              <el-radio-button :label="'rightTop'">右上角</el-radio-button>
              <el-radio-button :label="'leftBottom'">左下角</el-radio-button>
              <el-radio-button :label="'rightBottom'">右下角</el-radio-button>
          </el-radio-group>
      </el-form-item>
      <el-form-item label="是否展开">
          <el-radio-group v-model="com.popMenu">
              <el-radio-button :label="false">否</el-radio-button>
              <el-radio-button :label="true">是</el-radio-button>
          </el-radio-group>
      </el-form-item>
      <el-form-item label="展开方式" v-if="com.popMenu">
          <el-radio-group v-model="com.direction">
              <el-radio-button :label="'horizontal'">水平</el-radio-button>
              <el-radio-button :label="'vertical'">垂直</el-radio-button>
          </el-radio-group>
      </el-form-item>
      <el-form-item label="选择图标">
        <el-link  type="primary" href="https://uniapp.dcloud.net.cn/component/uniui/uni-icons.html" target="_blank">图标可用值<i class="el-icon-view el-icon--right"></i></el-link>
        <el-input v-model="com.styles.icon" placeholder="请输入图标值"></el-input>
      </el-form-item>
      <el-form-item label="图标颜色">
		      <el-color-picker v-model="com.styles.iconColor"></el-color-picker>
      </el-form-item>
      <el-form-item label="按钮颜色">
		      <el-color-picker v-model="com.styles.buttonColor"></el-color-picker>
      </el-form-item>
      <el-form-item label="字体颜色">
		      <el-color-picker v-model="com.styles.color"></el-color-picker>
      </el-form-item>
      <el-form-item label="选中字体色">
		      <el-color-picker v-model="com.styles.selectedColor"></el-color-picker>
      </el-form-item>
      <el-form-item label="展开背景色">
		      <el-color-picker v-model="com.styles.backgroundColor"></el-color-picker>
      </el-form-item>
    </el-form>
    <el-drawer ref="btmFieldl" :direction="'btt'" :with-header="false" :size="btmSize" :visible.sync="colVisible"
            :append-to-body="true">
        <div class="spliter" @mousedown="mousedown($event, $refs.btmFieldl)"></div>
        <div class="drw">
            <el-button type="primary" size="mini" @click="saveMethod">保存</el-button>
            <el-button type="primary" size="mini" @click="addField(com.icons)">添加字段</el-button>
            <el-button type="warning" class="el-icon-top" size="mini" v-if="currCol"
                @click="upGo(com.icons, currCol)">上移</el-button>
            <el-button type="warning" class="el-icon-bottom" size="mini" v-if="currCol"
                @click="downGo(com.icons, currCol)">下移</el-button>

            <el-dialog title="提示" v-if="dialogVisible" :visible.sync="dialogVisible" width="60%">
                <el-radio-group v-model="hasToken">
                    <el-radio :label="0">不带</el-radio>
                    <el-radio :label="1">带Token</el-radio>
                </el-radio-group>
                <el-row v-if="com.fun.prms" v-for="(value, key, index) in com.fun.prms" :key="index">
                    <el-col :span="8">{{ key }}</el-col>
                    <el-col :span="8"><el-input v-model="com.fun.prms[key]" placeholder="Token"></el-input></el-col>
                </el-row>
                <el-button plain size="mini" @click="getData" type="primary">提交</el-button>
                <el-input v-model="currentToken" placeholder="Token" v-if="hasToken === 1"></el-input>
            </el-dialog>
            <el-table :data="com.icons" stripe tooltip-effect="dark" style="width: 100%"
                :cellStyle="{ padding: '0px' }" highlight-current-row @current-change="currCol = $event">
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <div class="el-icon-close" @click="deleteGo(com.icons, scope.$index)"></div>
                        <el-checkbox v-model="scope.row.hide">隐藏</el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column label="标签" width="120">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.text"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="图标" width="120">
                    <template slot-scope="scope">
                      <selectorImg v-model="scope.row.iconPath"></selectorImg>
                    </template>
                </el-table-column>
                <el-table-column label="选中图标" width="120">
                    <template slot-scope="scope">
                      <selectorImg v-model="scope.row.selectedIconPath"></selectorImg>
                    </template>
                </el-table-column>
                <el-table-column width="110" label="动作类型">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.clickType" placeholder="请选择">
                          <el-option key="1" label="跳转(保留)" :value="'link'"></el-option>
                          <el-option key="6" label="跳转(不保留)" :value="'linkC'"></el-option>
                          <el-option key="2" label="请求接口" :value="'fun'"></el-option>
                          <el-option key="3" label="返回顶部" :value="'top'"></el-option>
                          <el-option key="4" label="客服" :value="'service'"></el-option>
                          <el-option key="5" label="电话" :value="'phone'"></el-option>
                      </el-select>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="动作行为">
                    <template slot-scope="scope">
                        <selectorPage v-if="scope.row.clickType == 'link'"
                            v-model="scope.row.linkC" :comData="comData"></selectorPage>
                        <selectorPage v-if="scope.row.clickType == 'linkC'"
                            v-model="scope.row.linkC" :comData="comData"></selectorPage>
                        <selectorFun v-if="['fun'].includes(scope.row.clickType)" v-model="scope.row.funC"
                            :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
                        <el-input v-model="scope.row.phone" placeholder="电话号码" v-if="scope.row.clickType == 'phone'"></el-input>
                        <el-input v-model="scope.row.kefuUrl" placeholder="客服链接" v-if="scope.row.clickType == 'service'"></el-input>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="初始化接口">
                    <template slot-scope="scope">
                        <selectorFun v-model="scope.row.defaultFun"
                            :comData="comData" placement="bottom-end" style="display: inline-block;"></selectorFun>
                    </template>
                </el-table-column>
                <el-table-column label="后置操作" width="150">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.after">
                            <el-option label="关闭菜单" value="close"></el-option>
                            <el-option label="返回" value="back"></el-option>
                            <el-option label="刷新" value="refresh"></el-option>
                            <el-option label="跳转页面(保留)" value="link"></el-option>
                            <el-option label="跳转页面(不保留)" value="linkC"></el-option>
                            <el-option label="无" value=""></el-option>
                        </el-select>
                        <selectorPage v-if="['link','linkC'].includes(scope.row.after)" v-model="scope.row.linkA" :type="'M'" :comData="comData"></selectorPage>
                    </template>
                </el-table-column>
                <el-table-column width="150" label="操作">
                    <!-- <template slot-scope="scope">
                        <jfStyle v-model="scope.row.style"></jfStyle>
                    </template> -->
                </el-table-column>
            </el-table>
            </el-tab-pane>
            </el-tabs>
        </div>
    </el-drawer>
      <!-- 接参设置 -->
      <el-drawer ref="btmTable" :direction="'btt'" :with-header="false" title="列表设置" :size="btmSize"
          :visible.sync="paramsVisible" :append-to-body="true">
          <jfParams v-model="com.params"></jfParams>
      </el-drawer>
    <jfExplain v-model="drawer" :name="com.cName || '占位'"  :desc="desc" :fieldData="fieldData"></jfExplain>
  </div>
</template>
<style>

</style>
<script>
export default {
  data () {
    return {
      drawer: false,
      desc: '悬浮按钮组件是悬浮于页面左上、右上、左下、右下四个角落的按钮组件，可展开按钮菜单，配置按钮图标、文本、操作，实现客服、电话、返回顶部、跳转页面、请求接口等操作',
      fieldData:[],
      locations: {
        'leftTop': ['left','top'],
        'rightTop': ['right','top'],
        'leftBottom': ['left','bottom'],
        'rightBottom': ['right','bottom'],
      },
      paramsVisible:false,
      dialogVisible: false,
      colVisible: false,
      btmSize: '400px',
      currBtn: null,
      currCol: null,
    }
  },
  methods: {
    handlerLocation(e) {
      let aligns = this.locations[e]
      this.com.horizontal = aligns[0]
      this.com.vertical = aligns[1]
    },
    addField(arr) {
        const ctrl = {
            type: 'text',
            field: '',
            label: '',
            "position": "right",
            "linkC":"",
            active:false,
            style: {}
        }
        arr.push(ctrl)
    },
    getData() {
        if (!this.dialogVisible) {
            if (!this.com.fun) this.$message.error('没有设置接口')
            else this.dialogVisible = true
            return
        }
        if (this.currentToken.length > 0) localStorage.setItem('testToken', this.currentToken)
        const opt = { server: this.$route.params.url, subsys: this.$route.params.subsys }
        if (this.hasToken) opt.token = this.currentToken
        else opt.withToken = false
        this.common.request(this.com.fun, this.setData.bind(this), opt)
    },
    setData(r) {
        if (r.code == 0) {
            this.com.data = r.data[this.com.listDataIndex].slice(0, 5)
            this.dialogVisible = false
        } else this.$message.error(r.msg)
    },
    getIndex(arr, row) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] == row) return i
        }
    },
    upGo(arr, row) {
        let index = this.getIndex(arr, row)
        if (index !== 0) {
            arr[index] = arr.splice(index - 1, 1, arr[index])[0]
        } else {
            arr.push(arr.shift())
        }
    },
    downGo(arr, row) {
        let index = this.getIndex(arr, row)
        if (index !== arr.length - 1) {
            arr[index] = arr.splice(index + 1, 1, arr[index])[0]
        } else {
            arr.unshift(arr.splice(index, 1)[0])
        }
    },
    deleteGo(arr, index) {
        arr.splice(index, 1)
    },
    mousedown(evt, sourceDom) {
        let height = parseInt(sourceDom.size)
        let pageY = evt.pageY
        let start = true
        this.$refs.fc.style.display = 'block'
        let that = this
        document.onmousemove = (event) => {
            if (start) {
                // sourceDom.height ='80%' //= height + event.pageY - pageY + "px";
                that.btmSize = sourceDom.size = height - (event.pageY - pageY) + "px";
            }
        };
        document.onmouseup = () => {
            this.$refs.fc.style.display = 'none'
            document.onmousemove = null;
            document.onmouseup = null;
            start = false
            return false;
        }
    },
  },
  props: {
    com: { coms: Array },
    comData: Object,
    saveMethod: Function
  }
}
</script>
