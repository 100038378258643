<template>
  <div class="bottomFooter" :style="[com?.bmStyle,{background:com.bgColor}]">
    <ul>
      <li
        class="fl"
        :class="'bottom'+ com.data.length"
        v-for="(item, index) in com.data"
        :key="index"
        :style="{color:item.color}"
      >
        <p class="footerImg" v-if="item.checkedImgUrl && index == com.current - 1" :style="[{borderRadius:com.borderRadius || '5px'},com?.imgStyle,com?.checkedImgStyle]">
			    <img class="g-img" :src="$route.params.url+item.checkedImgUrl" :onerror="common.imgOnError"/>
        </p>
        <p class="footerImg" v-else :style="[{borderRadius:com.borderRadius || '5px'},com?.imgStyle]">
			    <img class="g-img" :src="$route.params.url+item.imgUrl" :onerror="common.imgOnError"/>
        </p>
        <p class="footerText" :style="[com.textStyle,com.checkedTextStyle]" v-if="index == com.current - 1">{{item.text}}</p>
        <p class="footerText" :style="[com.textStyle]" v-else>{{item.text}}</p>
      </li>
      <p></p>
    </ul>
  </div>
</template>

<script>
export default {
  data () {
    return {
      data: null,
    }
  },
  created: function () {
  },
  methods:{
	  
  },
  props: {
    com: Object
  }
}
</script>

<style scoped lang="scss">

  .bottomFooter {
    width: 375px;
    /* position: absolute; */
    /* bottom: 0; */
    height: 60px;
    /* background: #fff; */
    /* z-index: 9; */
    /* border-top: 1px solid #eee; */

    ul {
      display: flex;
      width: 100%;
      align-items: center;
      height: 100%;
      li {
        width: 50%;
        text-align: center;
        font-size: 12px;
        color: rgb(51, 51, 51);

        .footerImg {
          img {
            height: 28px;
            width: 28px;
            display: block;
            margin: 6px auto;
          }
        }
      }

      .bottom2 {
        width: 50%;
      }
      .bottom3 {
        width: 33.33%;
      }
      .bottom4 {
        width: 25%;
      }
      .bottom5 {
        width: 20%;
      }
    }
  }
	.footerText {
		text-align: center;
		font-size: 22rpx;
	}
</style>
