<template>
	<el-descriptions title="基础设置" :column="2" border>
	  <el-descriptions-item label="系统名称">
		  <el-input v-model="cfg.sysName"></el-input>
	  </el-descriptions-item>
	  <el-descriptions-item label="LOGO">
		  <selectorImg v-model="cfg.logo"></selectorImg>
	  </el-descriptions-item>
		<el-descriptions-item label="PC首页">
					  <selectorPage v-model="cfg.homePc"></selectorPage>
		</el-descriptions-item>
		<el-descriptions-item label="手机首页">
			<selectorPage v-model="cfg.homeM"></selectorPage>
		</el-descriptions-item>
		<el-descriptions-item label="底部导航">
			<el-radio-group v-if="cfg.bm" v-model="cfg.bm.enable" size="mini">
			  <el-radio-button :label="true">启用</el-radio-button>
			  <el-radio-button :label="false">禁用</el-radio-button>
			</el-radio-group>
		</el-descriptions-item>
		
		<el-descriptions-item label="即时通讯">
			<el-radio-group v-model="cfg.im" size="mini">
			  <el-radio-button :label="true">启用</el-radio-button>
			  <el-radio-button :label="false">禁用</el-radio-button>
			</el-radio-group>
		</el-descriptions-item>
		
		<el-descriptions-item label="引导页">
			<el-radio-group v-model="cfg.prePage.enable" size="mini">
			  <el-radio-button :label="true">启用</el-radio-button>
			  <el-radio-button :label="false">禁用</el-radio-button>
			</el-radio-group>
		</el-descriptions-item>
		<el-descriptions-item v-if="cfg.prePage.enable" label="引导页类型">
			<el-radio-group v-model="cfg.prePage.type" size="mini">
			  <el-radio-button label="text">文字</el-radio-button>
			  <el-radio-button label="img">图片</el-radio-button>
			  <el-radio-button label="video">视频</el-radio-button>
			</el-radio-group>
		</el-descriptions-item>
		<el-descriptions-item v-if="cfg.prePage.enable" label="引导页内容">
			<el-input v-if="cfg.prePage.type=='text'" v-model="cfg.prePage.text"></el-input>
			<selectorImg v-if="cfg.prePage.type=='img'" v-model="cfg.prePage.img"></selectorImg>
			<selectorImg v-if="cfg.prePage.type=='video'" v-model="cfg.prePage.video"></selectorImg>
		</el-descriptions-item>
		<el-descriptions-item label="顶部导航" :span="2">
			<el-row style="line-height: 40px;border-bottom: 1px solid #eee;">
				<el-col :span="4">背景颜色：</el-col>
				<el-col :span="4">
					<el-color-picker v-model="cfg.top.bgColor"></el-color-picker>
				</el-col>
				<el-col :span="4">文本颜色：</el-col>
				<el-col :span="10">
					<el-radio-group v-model="cfg.top.color" size="mini">
						<el-radio-button label="#ffffff">白色</el-radio-button>
						<el-radio-button label="#000000">黑色</el-radio-button>
					</el-radio-group>
				</el-col>
			</el-row>
		</el-descriptions-item>
		<el-descriptions-item v-if="cfg.top" label="底部导航预览" :span="2">
			<bm v-if="cfg.bm" :com="cfg.bm"></bm>
		</el-descriptions-item>
		<el-descriptions-item v-if="cfg.bm.enable" label="底部导航编辑" :span="2">
			<bmEditor v-if="cfg.bm" :com="cfg.bm"></bmEditor>
		</el-descriptions-item>
		<el-descriptions-item label="" :span="2">
			<el-button @click="save" type="primary" plain size="mini">保存</el-button>
		</el-descriptions-item>
	</el-descriptions>
</template>

<script>
export default {
	data () {
	    return {
			bm: null,//{bgColor:'',enable: false,data:[]}, 
			cfg: {sysName:'',logo:'',homePc: '',homeM: '',top:{bgColor:"#e8e8e8",color:"#000000"},bm: {current:1,bgColor:'',enable: false,data:[]},prePage:{enable: false,type: 'text',text: '',img:'',video:''},im:false},
			loading: null,
		}
	},
	created: function () {
	    this.search()
	},
	methods: {
	    search () {
	      const fun = { module: 'sys', action: 'getSet', prms: {sysId: this.$route.params.id}}
	      this.common.request(fun, this.searchCb.bind(this))
	    },
	  	searchCb(r){
	  		if (r.code == 0) {
	  			this.model = r.data[0][0]
	  			if(this.model.cfg) this.cfg = Object.assign(this.cfg,JSON.parse(this.model.cfg))
	  		} else this.$message(r.msg)
	  	},
		save () {
		  const fun = { module: 'sys', action: 'updateSet', prms: {cfg: JSON.stringify(this.cfg),sysId: this.$route.params.id} }
		  this.common.request(fun, this.saveCallback.bind(this))
		},
		saveCallback (r) {
		  if (r.code == 0) this.$message({duration:600,type: "success",message:'保存成功'})
		  else this.$message.error(r.msg)
		}
	}
}
</script>

<style>
</style>