import { render, staticRenderFns } from "./jfBlank.vue?vue&type=template&id=0cba227a&scoped=true&"
import script from "./jfBlank.vue?vue&type=script&lang=js&"
export * from "./jfBlank.vue?vue&type=script&lang=js&"
import style0 from "./jfBlank.vue?vue&type=style&index=0&id=0cba227a&prod&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cba227a",
  null
  
)

export default component.exports