<template>
    <div class='jf-fab' :style="{background:com.styles.buttonColor}">
      <i class="el-icon-plus" :style="{color: com.styles.iconColor}"></i>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {},
    methods: {},
    props:{
      com:Object
    }
};
</script>

<style scoped>
  .jf-fab {
    width: 45px;
    height: 45px;
    position: fixed;
    right: 20px;
    top: 300px;
    border-radius: 50%;
    text-align: center;
    line-height: 45px;
  }
</style>
